import * as React from "react";
import connect from "react-redux/es/connect/connect";
// @ts-ignore
import moment from "moment";
import {injectIntl} from "react-intl";

class DatabaseVersionLabel extends React.Component<{ database: any, authList?: any, intl?: any, separator: any }, {}> {

    private readonly byOnBehalfOfUserMessage: string;

    constructor(props) {
        super(props);
        this.byOnBehalfOfUserMessage = this.props.intl.formatMessage({id: 'database.by_on-behalf-of-user'});
    }

    render() {
        const {database, separator} = this.props;
        const dateTimeString = moment(database.modified_at).format('DD/MM/YYYY - HH:mm');
        const sepStr = separator || '';
        let byUser = database.by_user_name ? `${sepStr}${database.by_user_name}` : '';
        if (byUser !== '' && this.props.authList.length > 1) {
            const authId = database.by_user_auth_id ? database.by_user_auth_id : 0;
            const auth = this.props.authList.filter(a => a.id === authId).first;
            if (auth) {
                byUser += ` (${auth.name})`;
            }
        }
        if (byUser !== '' && database.by_on_behalf_of_user_name) {
            byUser = `${byUser} ${this.byOnBehalfOfUserMessage} ${database.by_on_behalf_of_user_name}`
            const authId = database.by_on_behalf_of_user_auth_id ? database.by_on_behalf_of_user_auth_id : 0;
            const auth = this.props.authList.filter(a => a.id === authId).first;
            if (auth) {
                byUser += ` (${auth.name})`;
            }
        }
        if (database.is_draft) {
            return `${database.version} ${this.props.intl.formatMessage({id: 'draft'})} ${sepStr} ${dateTimeString}${byUser}`;
        }
        return (
            <span>
                <span>{database.version} </span> {sepStr}
                <span className="smaller"> {dateTimeString}</span>
                <span>{byUser}</span>
            </span>
        );
    }
}

const mapStateToProps = ({auth}) => {
    const authUser = auth.authUser;
    const authList = auth.list;
    return {
        authUser,
        authList
    };
};
export default injectIntl(connect(mapStateToProps)(DatabaseVersionLabel));